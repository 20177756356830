@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;

  font-family: 'Inter', 'Noto Sans JP', sans-serif;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --size: 10px;
}

.inputRange::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 0px;
  height: 0px;
  background: #ffffff;
  box-shadow: none;
}

.inputRange::-moz-range-thumb {
  width: 0px;
  height: 0px;
  background: #ffffff;
  box-shadow: none;
}

/* Chrome, Safari, Edge 用: トラックのスタイル */
.inputRange::-webkit-slider-runnable-track {
  height: var(--size);
  /* お好みで調整 */
  border-radius: 4px;
}

/* Firefox 用: トラックのスタイル */
.inputRange::-moz-range-track {
  height: var(--size);
  /* お好みで調整 */
  border-radius: 4px;
}